import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import { styled } from "@mui/material/styles";
import { SupportRequest } from "../onsight-plus-ext"
import { useSearchParams } from "react-router-dom";

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

export const Survey = (props) => {

    const [searchParams, _] = useSearchParams();

    const [extensionName, setExtensionName] = useState(null);
    const extensionNameRef = useRef(extensionName);
    extensionNameRef.current = extensionName;

    const [rating, setRating] = useState(4);
    const [comment, setComment] = useState("");

    const [showError, setShowError] = useState(false);
    const [showAlreadySubmitted, setShowAlreadySubmitted] = useState(false);
    const [submissionDate, setSubmissionDate] = useState(false);

    const setRatingValue = (event, newValue) => {
        setRating(newValue);
    };

    const setCommentValue = (event) => {
        setComment(event.target.value);
    };

    const saveSurvey = () => {
        handleRequestUpdate();
    };

    const parseUrl = async () => {
        const newParams = new URLSearchParams();
        for (const [name, value] of searchParams) {
            newParams.append(name.toLowerCase(), value);
        }

        let requestIdParam = newParams.get("requestid");
        let pageIdParam = newParams.get("pageid");

        if (!requestIdParam || !pageIdParam) {
            setShowError(true);
        } else {
            try {
                const surveyDate = await SupportRequest.getSurveyDate(pageIdParam, requestIdParam);
                if (surveyDate) {
                    setSubmissionDate(surveyDate)
                    setShowAlreadySubmitted(true);
                }
            }
            catch (error) {
                setShowError(true);
            }
        }
    };

    const handleRequestUpdate = async () => {
        const newParams = new URLSearchParams();
        for (const [name, value] of searchParams) {
            newParams.append(name.toLowerCase(), value);
        }

        let requestId = newParams.get("requestid");
        let pageId = newParams.get("pageid");
        const surveyDate = await SupportRequest.updateSurvey(pageId, requestId, {
            rating,
            comment
        });

        setSubmissionDate(surveyDate);
        setShowAlreadySubmitted(true);
    };

    useEffect(() => {
        parseUrl();
    }, []);

    if (!showError && !showAlreadySubmitted) {
        return (
            <Stack spacing={2}>
                <Item></Item>
                <Item>
                    <Box
                        sx={{
                            "& > legend": { mt: 2 },
                        }}
                    >
                        <Grid container spacing={0}>
                            <Grid xs={3}><Item></Item></Grid>
                            <Grid xs={6} sx={{ textAlign: "center" }}>
                                <Item>
                                    <Typography variant="h6" component="legend">
                                        Please rate your support experience
                                    </Typography>
                                </Item>
                                <Item>
                                    <Rating
                                        size="large"
                                        name="simple-controlled"
                                        value={rating}
                                        onChange={(event, newValue) => {
                                            setRatingValue(event, newValue);
                                        }}
                                    />
                                </Item>
                                <Box sx={{ width: "100%", textAlign: "center" }}>
                                    <Grid container spacing={0}>
                                        <Grid xs={1}></Grid>
                                        <Grid xs={10}>
                                            <Box
                                                sx={{
                                                    border: "solid",
                                                    borderColor: "black",
                                                    borderWidth: "1px",
                                                }}
                                            >
                                                <Item sx={{ textAlign: "left" }}>
                                                    <Typography
                                                        variant="caption"
                                                        component="legend"
                                                    >
                                                        How could we have done better?
                                                    </Typography>
                                                </Item>
                                                <Item
                                                    sx={{ padding: "0px 0px 7px 0px" }}
                                                >
                                                    <TextField
                                                        sx={{ width: "95%" }}
                                                        id="outlined-multiline-flexible"
                                                        multiline
                                                        rows={4}
                                                        value={comment}
                                                        onChange={(event) => {
                                                            setCommentValue(event);
                                                        }}
                                                    />
                                                </Item>
                                            </Box>
                                        </Grid>
                                        <Grid xs={1}><Item></Item></Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ width: "100%", textAlign: "center" }}>
                                    <Item sx={{ paddingTop: "30px" }}>
                                        <Button
                                            onClick={() => saveSurvey()}
                                            variant="contained"
                                        >
                                            Done!
                                        </Button>
                                    </Item>
                                </Box>
                            </Grid>
                            <Grid xs={3}></Grid>
                        </Grid>
                    </Box>
                </Item>
                <Item></Item>
            </Stack>
        );
    } else if (showError) {
        return (
            <Stack spacing={2}>
                <Item></Item>
                <Item>
                    <Box
                        sx={{
                            "& > legend": { mt: 2 },
                        }}
                    >
                        <Grid container spacing={0}>
                            <Grid xs={3}><Item></Item></Grid>
                            <Grid xs={6} sx={{ textAlign: "center" }}>
                                <Item>
                                    <Typography variant="h6" component="legend">
                                        Unable to locate request. <br />Please ensure the <strong>requestId</strong> and <strong>pageId</strong> are valid in the URL.
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid xs={3}></Grid>
                        </Grid>
                    </Box>
                </Item>
                <Item></Item>
            </Stack>
        );
    } else if (showAlreadySubmitted) {
        return (
            <Stack spacing={2} >
                <Item></Item>
                <Item>
                    <Box
                        sx={{
                            "& > legend": { mt: 2 },
                        }}
                    >
                        <Grid container spacing={0}>
                            <Grid xs={3}><Item></Item></Grid>
                            <Grid xs={6} sx={{ textAlign: "center" }}>
                                <Item>
                                    <Typography variant="h6" component="legend">
                                        Thank you for your response!
                                    </Typography>
                                    <Typography variant="caption" component="legend">
                                        Submitted:
                                        {new Date(submissionDate).toLocaleDateString()} at {new Date(submissionDate).toLocaleTimeString()}
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid xs={3}></Grid>
                        </Grid>
                    </Box>
                </Item>
                <Item></Item>
            </Stack>
        )
    }
};
