import React, { useEffect, useState } from "react";
import { Breadcrumb, Container, Form, Navbar, ProgressBar, Spinner } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom"
import { FileStorage } from "../onsight-plus-ext";
import { formatBytes } from "../utils/numbers";
import ConfirmationModal from "../components/ConfirmationModal";
import axios from "axios";

import "../styles/index.css";
import "../styles/App.css";

const EXT_NAME_ID = "support-request";


export const FileListing = (props) => {
    const [listing, setListing] = useState({folderName:"", files:[]});
    const [pathParts, setPathParts] = useState([]);
    const [currentFolder, setCurrentFolder] = useState("");
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const location = useLocation();
    document.title = "Customer Files";
    
    const loadFiles = async () => {
        setIsBusy(true);

        const secondSlash = location.pathname.indexOf('/', 1);
        const rootPath = secondSlash < 0
            ? "/"
            : location.pathname.substring(secondSlash);

        const list = await FileStorage.listFiles(EXT_NAME_ID, rootPath);

        let pathBuilder = props.rootPath;
        const pparts = [{name: "Home", path: pathBuilder}];
        const parts = list.folderName.split('/');
        for (const part of parts) {
            pathBuilder += `/${part}`;
            pparts.push({name: part, path: pathBuilder});
        }

        setIsBusy(false);
        setPathParts(pparts.slice(0, pparts.length - 1));
        setCurrentFolder(pparts[pparts.length - 1].name)
        setListing(list);
    };

    const getFullFilePath = (folderName, fileName) => {
        if (folderName.length === 0) {
            return `/${fileName}`;
        }
        if (folderName.startsWith('/')) {
            folderName = folderName.substring(1);
        }

        return `/${folderName}/${fileName}`;
    };

    const onDownloadFile = async (evt) => {
        // Check for dummy link; if so, we need to call API to give us the real download link
        if (evt.target.href.endsWith('#')) {
            evt.preventDefault();
            const filePath = getFullFilePath(listing.folderName, evt.target.dataset["filename"]);
            evt.target.href = await FileStorage.getDownloadUrl(EXT_NAME_ID, filePath);
            console.log(evt.target.href);

            // Now that we have the real download link, retrigger the onClick event
            const newEvent = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: true
            });
            evt.target.dispatchEvent(newEvent);
        }
    };

    const tryDelete = (fileName, index) => {
        setSelectedFileName(fileName);
        setSelectedItemIndex(index);
        setShowDeleteConfirmation(true);
    };

    const deleteSelectedFile = async () => {
        try {
            const fullPath = getFullFilePath(listing.folderName, selectedFileName);
            await FileStorage.deleteFile(EXT_NAME_ID, fullPath);
            await loadFiles();
            setShowDeleteConfirmation(false);
        } catch (error) {
            console.log(error);
        }
    };

    const uploadFile = async (evt) => {
        setIsUploading(true);
        const file = evt.target.files[0];
        const fileType = file.type || "application/octet-stream";
        const fullPath = getFullFilePath(listing.folderName, file.name);
        let url = await FileStorage.getUploadUrl(EXT_NAME_ID, fullPath, fileType);

        const config = {
          headers: {
            "Content-Type": fileType,
            "Content-Disposition": "attachment"
          },
          onUploadProgress: (evt) => {
            setUploadPercentage(Math.round((evt.loaded * 100) / evt.total));
          }
        };
        
        try {
            setUploadPercentage(0);
            await axios.put(url, file, config);
            await loadFiles();
        }
        catch (error) {
            console.error(error);
        }

        setIsUploading(false);
        setUploadPercentage(0);
        document.getElementById("fileInput").value = "";
    };

    useEffect(() => {
        loadFiles();
    }, [location]);

    return (
        <>
            <Navbar bg="light" data-bs-theme="light">
                <Container fluid>
                    <Form className="d-flex">
                        <label htmlFor="fileInput" className={`btn btn-primary ${isUploading ? "disabled": ""}`}>Upload...</label>
                        <input id="fileInput" type="file" onChange={uploadFile} style={{display: "none"}} disabled={isUploading} />
                    </Form>
                    <Navbar.Brand href="/">
                        <img
                            alt=""
                            src="logo2x.png"
                            width="181"
                            height="44"
                            className="d-inline-block align-top" />
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <div className="p-4">
                <Breadcrumb>
                    {pathParts.map((pp, idx) => <Breadcrumb.Item key={idx} linkAs={Link} linkProps={{ to: `${pp.path}` }}>{pp.name}</Breadcrumb.Item>)}
                    <Breadcrumb.Item active>{currentFolder}</Breadcrumb.Item>
                </Breadcrumb>
                
                <ProgressBar now={uploadPercentage} style={{width: "100%", marginTop: "8px", visibility: isUploading ? "inherit" : "hidden"}} />

                <p />
                <table className="table">
                    <thead>
                        <tr>
                            <th className="left">Name</th>
                            <th className="center">Type</th>
                            <th className="right">Size</th>
                            <th className="right">Modified</th>
                            <th className="right"></th>
                        </tr>
                    </thead>
                    <tbody
                        data-rbd-droppable-id="foo"
                        data-rbd-droppable-context-id="0">
                            {listing.files.map((f, idx) => 
                                <tr
                                    data-rbd-draggable-context-id="0"
                                    data-rbd-draggable-id="0"
                                    key={f.name}>
                                    <td className="left" style={{ width: "fit-content" }}>
                                        {f.isDirectory &&
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-folder" viewBox="0 0 16 16">
                                                <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a2 2 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139q.323-.119.684-.12h5.396z"/>
                                            </svg>
                                            &nbsp;&nbsp;
                                            <Link to={`${location.pathname}/${encodeURIComponent(f.name)}`}>
                                                {f.name}
                                            </Link>
                                        </div>}
                                        {!f.isDirectory &&
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                                <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
                                                <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                                            </svg>
                                            &nbsp;&nbsp;
                                            <a id={`a-${idx}`} onClick={onDownloadFile} href="#" download data-filename={f.name}>                                           
                                                {f.name}
                                            </a>
                                        </div>
                                        }
                                    </td>
                                    <td className="center" style={{ width: "auto" }}>
                                        {!f.isDirectory && f.contentType}
                                    </td>
                                    <td className="right" style={{ width: "auto" }}>
                                        {!f.isDirectory && formatBytes(f.size, 1)}
                                    </td>
                                    <td className="right" style={{ width: "auto" }}>
                                        {!f.isDirectory && new Date(f.lastModified).toLocaleString()}
                                    </td>
                                    <td className="right" style={{ width: "fit-content" }}>
                                        {!f.isDirectory &&
                                        <div className="tableRowAction">
                                            <button
                                                type="button"
                                                aria-expanded="false"
                                                title="Delete file"
                                                className="btn btn-secondary btn-sm"
                                                onClick={() => tryDelete(f.name, idx)}
                                                data-filename={f.name}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-trash3" viewBox="0 1 16 17">
                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                                                    </svg>
                                            </button>
                                        </div>}
                                    </td>
                                </tr>)}
                    </tbody>
                </table>

                <Spinner animation="border" style={{display: isBusy ? "inherit": "none" }} />
            </div>

            <ConfirmationModal
                show={showDeleteConfirmation}
                title="Delete File"
                item={selectedFileName}
                itemId={selectedItemIndex}
                onYesClick={deleteSelectedFile}
                onNoClick={() => setShowDeleteConfirmation(false)}>
                Are you sure you want to delete '{selectedFileName}'?
            </ConfirmationModal>
        </>
    );
};
