import React, { useState, useEffect, useRef } from "react";
import { Link, matchRoutes, Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { Container as ReactContainer, Nav, Navbar } from "react-bootstrap";
import { RestrictedPage } from "./pages/RestrictedPage";
import { LoadingPage } from "./pages/LoadingPage";
import { UserWebApps } from "./onsight-plus-ext";
import { Container } from "./components/dashboard/Container";
import { Admin } from "./components/dashboard/Admin"
import { NotFound } from "./components/NotFound";
import { setCurrentPage } from "./index";
import { FileListing } from "./components/FileListing";

import "./styles/index.css";
import "./styles/App.css";

import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const App = ({ authService }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [appPages, setAppPages] = useState([]);
  const [pageId, setPageId] = useState("");
  const pageIdRef = useRef("");
  pageIdRef.current = pageId;
  const [configId, setConfigId] = useState("");
  const configIdRef = useRef("");
  configIdRef.current = configId;
  const [searchParams, _] = useSearchParams();
  const [userRoutes, setUserRoutes] = useState([]);

  const [isAdmin, setIsAdmin] = useState(false);
  const isAdminRef = useRef(false);
  isAdminRef.current = isAdmin;

  const [isProd, setIsProd] = useState(true);
  const isProdRef = useRef(true);
  isProdRef.current = isProd;

  const elementForPage = (page) => {
    // Survey must allow anonymous access, so we define its Route from index.js
    // (outside the MsalAuthenticationTemplate block). Don't redefine it here.
    if (page.name === "survey") {
      return <></>;
    }
    if (page.name === "Customer Files")  {
      return <></>;
    }

    // Otherwise, assume the page does use the UIBuilder
    return <Container pageId={page.id} configId={page.configurations[0].configId} userAccount={currentUser} isAdmin={false} isProd={isProdRef.current} />;
  };

  const onAuthorize = async (userAccount) => {
    setCurrentUser(userAccount);
    let pageIdParam = searchParams.get("pageId");
    pageIdRef.current = pageIdParam;
    setPageId(pageIdParam);

    let configIdParam = searchParams.get("configId");
    configIdRef.current = configIdParam;
    setConfigId(configIdParam);

    try {
      userAccount.roles.forEach((r) => {
        if (r.includes("Administrator")) {
          isAdminRef.current = true;
          setIsAdmin(true);
        }
      });

      // determine if this is being launch in non-dev environment
      if (isAdminRef.current) {
        const url = window.location;
        if (url.hostname === "localhost") {
          isProdRef.current = false;
          setIsProd(false);
        }
      }

      const userPages = await UserWebApps.getUserPages();
      setAppPages(userPages);

      const userRoutes = userPages
        .map((page) => {
          return (
              <Route {...page}
                  key={page.id}
                  element={elementForPage(page)}
              />
          )
      });

      // Add some standard routes in addition to user-specific ones
      userRoutes.push(<Route key="_404" path="*" element={<NotFound status={404} />} />);

      if (isAdminRef.current) {
        userRoutes.push(
          <Route
            key="_admin"
            path="/admin"
            element={<Admin pageId={pageIdRef.current} configId={configIdRef.current} userAccount={currentUser} isAdmin={true} isProd={isProdRef.current} />}
          />
        );
      }

      setUserRoutes(userRoutes);
      setIsAuthorized(true);
    } catch (error) {
      setIsAuthorized(false);
      setNotAuthorized(true);
    }
  };

  useEffect(() => {
    authService.addAuthorizationHandler(onAuthorize);
  }, []);

  useEffect(() => {
    if (appPages !== null && appPages !== undefined) {
      const matches = matchRoutes(appPages, location);
      if (matches?.length > 0) {
          setCurrentPage(matches[0].route);
      }
    }
  }, [appPages]);

  return (
    <>
      {isAuthorized && 
      <>
        {/* <Link to="/customer-files" target="_blank">Customer Files</Link> */}
        <Navbar bg="dark" data-bs-theme="light" variant="dark">
            <ReactContainer fluid>
                <Nav className="me-auto">
                    <Nav.Link href="/customer-files" target="_blank">
                      <span>Customer Files</span>
                      &nbsp;
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 22">
                        <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                        <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                      </svg>
                    </Nav.Link>
                </Nav>
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src="logo2x.png"
                        width="181"
                        height="44"
                        className="d-inline-block align-top" />
                </Navbar.Brand>
            </ReactContainer>
        </Navbar>
        <Routes>{userRoutes}</Routes>
      </>}
      {notAuthorized && <RestrictedPage />}
      {!isAuthorized && !notAuthorized && <LoadingPage />}
    </>
  );
};
export default App;
